<template>
    <div class="login">
        <div class="flex1 login1">

        </div>
        <div class="flex2 login2">
            <div class="login2a">
                <h1 class="tc col24 font26 padb30">账户密码登陆</h1>
                <el-input placeholder="输入手机号/邮箱号" size="large" v-model="mobileOrEmail" class="bora4" />
                <p class="h30"></p>
                <el-input v-model="password" placeholder="请输入密码" size="large" show-password />
                <p class="h40"></p>
                <el-button :loading="loading" type="primary" size="large" @click="tologin"  class="w100">登 录</el-button>
 
                <div class="flex flexsa w100 col4c font14 padt30 padb10">
                    <router-link to="/register" >新用户注册</router-link>
                    <router-link to="/password" >忘记密码</router-link>
                </div>
                <div class="logintxt">
                    每个公司客户都有唯一的客户服务号（CSI），只有注册时关联了CSI号且CSI在有效期内的账户才能提交技术工单
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { accountLogin } from "@/utils/api1.js"
export default {
    data(){
        return{
            loading: false,
            mobileOrEmail: '',
            password: '',
        }
    },
    methods:{
        tologin(){
            var that = this;
            that.loading = true;
            // console.log(this.mobileOrEmail,this.password)
            let param = {
                mobileOrEmail:this.mobileOrEmail,
                password: this.password
            };
            // let param = {
            //     mobileOrEmail:'11112341230',password: '123'
            // };

            accountLogin(param).then(res=>{
                console.log(res);
                that.loading = false;
                if(res){
                    that.$store.commit('setToken', res.token);
                    that.$store.commit('setIsbind', res.isBind);
                    that.$store.commit('setUserinfo', JSON.stringify(res) );
                    that.$router.push('/service')
                }
            });
           
        }
    }

}
</script>
<style lang="scss" scoped>
.login{
    min-height: 100vh;
    display: flex;
    background: #fff;
    .login1{
        background: url(../../assets/imgs/bguser.png) no-repeat center center;
        background-size: cover;
    }
    .login2{
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .login2a{
        width: 366px;
    }
    .logintxt{
        padding: 17px;
        line-height: 20px;
        color: #989898;
        font-size: 12px;
        text-align: center;
        border-top: 1px dashed #CCCCCC;
    }
}

</style>